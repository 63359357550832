.blink {
    animation: blinker 0.6s linear infinite;
    color: #1c87c9;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.blink-one {
    animation: blinker-one 1s linear infinite;
}

@keyframes blinker-one {
    0% {
        opacity: 0;
    }
}

.blink-two {
    animation: blinker-two 1.4s linear infinite;
}

@keyframes blinker-two {
    100% {
        opacity: 0;
    }
}